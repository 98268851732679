import { Box, Grid, Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { Product } from "../../../../domain";
import { Form, Hint } from "../../../../theme";
import { inputValidation } from "../../../../util";
import { updateNotes, updatePersonalizationTo } from "./state";

const notesInputProps = {
  ...inputValidation.notes,
  id: "product-modal-form-notes",
};

const personalizationToInputProps = {
  ...inputValidation.personalizationTo,
  id: "product-modal-form-personalization-to",
};

const ProductModalForm = React.memo(function ProductModalForm({
  product,
  state,
  dispatch,
}) {
  const isUnpersonalizedSignedType = product.isPresigned || product.isUnsigned;
  const isPersonalized =
    product.allowPersonalization && !isUnpersonalizedSignedType;

  React.useEffect(() => {
    if (!isPersonalized) {
      dispatch(updatePersonalizationTo(""));
    }
  }, [product, isUnpersonalizedSignedType, dispatch, isPersonalized]);

  return (
    <Box pt={1}>
      <Grid container spacing={1}>
        {isPersonalized && (
          <Grid item xs={12} container>
            <Grid
              item
              container
              xs={12}
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <label htmlFor="product-modal-form-personalization-to">
                <StyledLabel gutterBottom variant="body2">
                  Make it out to (optional)
                </StyledLabel>
              </label>

              <StyledLabel gutterBottom variant="caption">
                {state.personalizationTo.length > 0
                  ? `${state.personalizationTo.length} / ${inputValidation.personalizationTo.maxLength}`
                  : ""}
              </StyledLabel>
            </Grid>

            <Grid item xs={12}>
              <Form.Input
                fullWidth
                disabled={
                  isUnpersonalizedSignedType || !product.allowPersonalization
                }
                variant="outlined"
                value={product.isPresigned ? "" : state.personalizationTo}
                onChange={(evt) => {
                  if (
                    evt.target.value.length >
                    inputValidation.personalizationTo.maxLength
                  ) {
                    return;
                  }
                  dispatch(updatePersonalizationTo(evt.target.value));
                }}
                inputProps={personalizationToInputProps}
                placeholder={placeholderTextFor({
                  product,
                  filter: (product) => !product.allowPersonalization,
                  defaultText: "Enter your name...",
                })}
              />
            </Grid>
          </Grid>
        )}

        <Grid item xs={12} container>
          <Grid
            item
            xs={12}
            container
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <label htmlFor="product-modal-form-notes">
              <StyledLabel gutterBottom variant="body2">
                Special Instructions (optional)
              </StyledLabel>
            </label>

            <StyledLabel gutterBottom variant="caption">
              {state.notes.length > 0
                ? `${state.notes.length} / ${inputValidation.notes.maxLength}`
                : ""}
            </StyledLabel>
          </Grid>

          <Grid item xs={12}>
            <Form.Input
              fullWidth
              multiline
              rows={3}
              variant="outlined"
              value={product.isPresigned ? "" : state.notes}
              onChange={(evt) => {
                if (evt.target.value.length > inputValidation.notes.maxLength) {
                  return;
                }
                dispatch(updateNotes(evt.target.value));
              }}
              inputProps={notesInputProps}
              disabled={isUnpersonalizedSignedType || !product.allowNotes}
              placeholder={placeholderTextFor({
                product,
                filter: (product) => !product.allowNotes,
              })}
            />
          </Grid>
        </Grid>
        {product.isDigitalSignature && (
          <Hint>
            While the signer will be able to read the custom message you provide
            here, this product cannot be personalized with a name or quote,
            because it is signed digitally.
          </Hint>
        )}
      </Grid>
    </Box>
  );
});

const StyledLabel = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.interFontStack,
  fontWeight: theme.typography.fontWeightMedium,
  color: theme.palette.grey[800],
}));

ProductModalForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  state: PropTypes.shape({
    personalizationTo: PropTypes.string.isRequired,
    notes: PropTypes.string.isRequired,
  }),
  product: PropTypes.instanceOf(Product),
};

function placeholderTextFor({
  product,
  filter = (product) => !product.allowNotes,
  defaultText = "",
}) {
  if (product.isPresigned) {
    return "Unavailable for Pre-Signed items";
  }

  if (product.isUnsigned) {
    return "Unavailable for Unsigned items";
  }

  if (filter(product)) {
    return "Unavailable for this product";
  }

  return defaultText;
}

export default ProductModalForm;
