import { Box, Grid, Hidden, Link, Typography } from "@material-ui/core";
import { formatDistance, differenceInDays, isAfter } from "date-fns";
import { styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { Influencer, Product, SignType } from "../../../../domain";
import { GradientText } from "../../../../theme";
import * as utils from "../../utils";
import ProductModalHeaderPrice from "./ProductModalHeaderPrice";
import ProductModalHeaderUrgency from "./ProductModalHeaderUrgency";
import ProductModalSendinInfo from "./ProductModalSendinInfo";
import FulfillmentSpeed, {
  HIDDEN,
  sortBySpeed,
} from "../../../FulfillmentSpeed";

function speedFor({ product, influencer }) {
  if (Array.isArray(product.fulfillers) && product.fulfillers.length) {
    const [slowest] = Array.from(
      new Set(
        product.fulfillers.map((fulfiller) => fulfiller.fulfillmentSpeed),
      ),
    ).sort(sortBySpeed("asc"));

    return slowest ?? HIDDEN;
  }

  return influencer?.fulfillmentSpeed;
}

function isNonLiveSigningProduct(product) {
  const signedType = product?.signedType;

  return (
    signedType &&
    (SignType.isSignAnytime(signedType) ||
      SignType.isSignedOffCamera(signedType))
  );
}

function describeDistance(aDate, bDate) {
  if (!aDate || !bDate) {
    return "";
  }

  const days = differenceInDays(aDate, bDate);

  if (days > 0 && isAfter(aDate, bDate)) {
    return ` (in ${formatDistance(aDate, bDate)})`;
  }

  return "";
}

function hasMultipleSigners(product) {
  return product && product.fulfillers.length > 1;
}

const ProductModalHeader = React.memo(function ProductModalHeader({
  product,
  influencer,
}) {
  const signerLinks = React.useMemo(() => {
    const links = utils.signerLinksFor(product);

    return links.reduce((result, { name, href }, i) => {
      if (href) {
        result.push(
          <Typography key={name} variant="caption">
            <Link href={href} aria-label={`Go to ${name}'s shop`}>
              {name}
            </Link>
          </Typography>,
        );
      } else {
        result.push(
          <Typography key={name} variant="caption">
            {name}
          </Typography>,
        );
      }

      if (i !== links.length - 1) {
        result.push(
          <Typography key={i} variant="caption">
            ,&nbsp;
          </Typography>,
        );
      }

      return result;
    }, []);
  }, [product]);

  const signedTypeText = React.useMemo(() => {
    let description = SignType.getSignedTypeDescription(product);

    if (!description) {
      return null;
    }

    // TODO: Hardcoded logic for Crispin Freeman WeebCon Send-Ins. This is very bad and should be removed some day.
    const crispinFreemanWeebconSendinProductIds = [42849, 42848, 42889, 42891];
    if (
      crispinFreemanWeebconSendinProductIds.indexOf(product.productId) !== -1
    ) {
      description = {
        text: "Your Send-In will be signed by Crispin at one of four LIVE virtual meet-and-greet sessions during the event. Please choose the group with the corresponding time that you will be available to meet the talent and to see your item signed.",
      };
    }

    if (description.note) {
      return (
        <>
          <GradientText variant="caption">{description.text}</GradientText>
          <br />
          <small>
            <strong>{description.note}</strong>
          </small>
        </>
      );
    }

    return <GradientText variant="caption">{description.text}</GradientText>;
  }, [product]);

  return (
    <Grid container>
      <Grid item container alignItems="flex-end" xs>
        <Grid item>
          <StyledProductName variant="h6">{product.name}</StyledProductName>
        </Grid>

        <Hidden smDown>
          <Grid item xs="auto">
            <Box px={1} style={{ marginBottom: -2 }}>
              <Typography variant="caption">{product.productType}</Typography>
            </Box>
          </Grid>
        </Hidden>

        <Grid item xs container justifyContent="flex-end" alignItems="center">
          {product && (
            <Box mr={2}>
              <FulfillmentSpeed
                multisign={product.fulfillers.length > 1}
                speed={speedFor({ product, influencer })}
              />
            </Box>
          )}

          <ProductModalHeaderPrice product={product} influencer={influencer} />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Box pt={0.25}>
          <Typography variant="caption">Signed by:&nbsp;</Typography>
          {signerLinks}
        </Box>
      </Grid>

      <Grid item xs>
        <Box pt={0.5}>
          <Typography variant="body2">{product.subtext}</Typography>

          {signedTypeText}
        </Box>
      </Grid>

      <Grid item xs="auto">
        <ProductModalSendinInfo product={product} />
      </Grid>

      <Hidden mdUp>
        <Grid item xs={12}>
          <Typography variant="caption">{product.productType}</Typography>
        </Grid>
      </Hidden>

      {influencer?.nextNonLiveEvent &&
        isNonLiveSigningProduct(product) &&
        !hasMultipleSigners(product) && (
          <Grid item xs={12}>
            <Box pt={0.5}>
              <Typography gutterBottom variant="body2">
                {influencer?.name} will sign this <em>pre-order</em> on or
                around {influencer?.nextNonLiveEvent.formattedNextEventTime()}
                {describeDistance(
                  influencer?.nextNonLiveEvent.value,
                  new Date(),
                )}
                .
              </Typography>
            </Box>
          </Grid>
        )}

      {isNonLiveSigningProduct(product) && hasMultipleSigners(product) && (
        <Grid item xs={12}>
          <Box pt={0.5}>
            <Typography gutterBottom variant="body2">
              Since this <em>pre-order</em> will be signed by multiple people,
              it could take months before it is signed.
            </Typography>
          </Box>
        </Grid>
      )}

      <Grid item xs={12} style={{ paddingTop: "4px" }}>
        <ProductModalHeaderUrgency />
      </Grid>
    </Grid>
  );
});

ProductModalHeader.propTypes = {
  influencer: PropTypes.instanceOf(Influencer),
  product: PropTypes.instanceOf(Product),
};

const StyledProductName = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.interFontStack,
  fontWeight: theme.typography.semiBold,
  margin: 0,
  lineHeight: 1,
}));

export default ProductModalHeader;
